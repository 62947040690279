import { template as template_27e20597537047f3b5f2122f9e0b5f35 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_27e20597537047f3b5f2122f9e0b5f35(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
