import { template as template_b4ffd5a5835a4a7090baf87ac5569836 } from "@ember/template-compiler";
const FKText = template_b4ffd5a5835a4a7090baf87ac5569836(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
