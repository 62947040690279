import { template as template_116bc28dba80452cb28ed1335cc2cb51 } from "@ember/template-compiler";
const FKControlMenuContainer = template_116bc28dba80452cb28ed1335cc2cb51(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
